<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What things should you do to prepare for the laboratory work, <b>before</b> you get to the
        laboratory (check all 8 that apply)?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemOleMiss116SSQ0Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: 'Read the procedure', value: 'read'},
        {text: 'Perform a simulation if there is one', value: 'sim'},
        {text: 'Answer the pre-lab questions', value: 'prelab'},
        {text: 'Find and watch relevant videos', value: 'videos'},
        {text: 'Prepare your notebook', value: 'notebook'},
        {text: 'Review the appropriate SDS information', value: 'sds'},
        {text: 'Check the post-lab questions', value: 'postlab'},
        {text: 'Prepare questions for your TA', value: 'taQuestions'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
